const colors = {
	blue: {
		100: '#E8EBFF',
		300: '#ADBAFF',
		500: '#7388FF',
		700: '#072988',
		900: '#0027FF',
	},
	red: {
		100: '#FDE4E9',
		300: '#FFADBF',
		500: '#FF7491',
		700: '#A525CC',
		900: '#FF0036',
	},
	gray: {
		100: '#EDEDED',
		300: '#BFBFBF',
		500: '#838383',
		700: '#393939',
		900: '#000000',
	},
	black: {
		100: '#14000A',
		300: '#14000A',
		500: '#14000A',
		700: '#14000A',
		900: '#14000A',
	},
};

export default colors;
