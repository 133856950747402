const Text = {
	baseStyle: {
		color: '#515E63',
		fontSize: '16px',
		fontWeight: '400',
	},
	defaultProps: {},
};

export default Text;
