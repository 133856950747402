const Modal = {
	baseStyle: {
		dialog: {
			borderRadius: '16px',
			bg: 'rgba(0, 0, 255, 0.5)',
		},
	},
};

export default Modal;
